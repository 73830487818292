import { getToken } from "@/helpers";
import { apiRequest } from "@/helpers/api-call";
import { dataApiRequest } from "@/helpers/data-api-client";
import {
  GqlAccount,
  GqlAdminStats,
  GqlCategory,
  GqlShop,
  GqlSubscription,
  GqlTransaction,
  GqlUser,
  GqlUserStats,
  GqlUserTransaction,
} from "@/helpers/gql-schema";
import { VuexPick } from "@/helpers/vuex-type-helper";
import { TransactionStatus } from "shared-dependancies";
import { commonActionContext, FullShopInfo } from ".";

const lastCreatedAccountFragment =
  "{id name firstName shopCount account {creationDate}}";

const topReferrersFragment =
  "{ id name firstName shopCount stats {referralsCount availableFund} }";

const pictureFragment = "{ url }";

const categoriesFragment = `{ id label shopCount picture ${pictureFragment} normalIcon ${pictureFragment} featuredIcon ${pictureFragment} }`;

const citiesFragment = `{ id name department }`;

const displayableShopFragment = `{ id name hasActivePromotion location { lat lng } category ${categoriesFragment} city ${citiesFragment} firstPicture { url } }`;

const promotionFragment =
  "{ id isActive startDate endDate price payPhone promotionText gallery {pictures {url}}}";

const fullShopInfoFragment = `{ 
  id name 
  location { lat lng } 
  category ${categoriesFragment} 
  city ${citiesFragment} 
  contact1
  contact2
  description
  gallery {
    pictures { url }
  }
  manager {
    firstName
    name
  }
  currentlyActivePromotion ${promotionFragment}
}`;

export type LightUser = VuexPick<
  GqlUser,
  "id" | "name" | "firstName" | "isAdmin"
> & {
  account: GqlAccount;
};

export type TopReferrer = LightUser & { stats: GqlUserStats };

export type AdminStats = GqlAdminStats & {
  topReferrers: TopReferrer[];
  lastCreatedAccounts: (LightUser & {
    account: { creationDate: string };
    shopCount: number;
  })[];
};

export type LightMerchantDetail = Omit<LightUser, "shopCount"> & {
  account: VuexPick<GqlAccount, "telephone" | "creationDate"> & {
    referrer: LightUser;
  };
};

export type FullMerchantDetail = Omit<LightUser, "shopCount"> & {
  stats: GqlUserStats;
  shops: VuexPick<
    GqlShop,
    | "id"
    | "name"
    | "contact1"
    | "contact2"
    | "categoryId"
    | "category"
    | "city"
    | "insertionDate"
  >[];
  referrals: (LightUser & {
    account: { creationDate: string; telephone: string };
  })[];
  transactions: (GqlUserTransaction & {
    transactionStatus: TransactionStatus;
  })[];
  account: VuexPick<GqlAccount, "telephone" | "creationDate"> & {
    referrer: LightUser;
  };
};

export type Subscription = GqlSubscription & {
  user: LightUser;
  shop: VuexPick<GqlShop, "id" | "name"> & {
    category: GqlCategory;
  };
};

export type Transaction = GqlTransaction & {
  status: TransactionStatus;
  user: LightUser;
};

export default {
  async fetchAdminStats(context: any): Promise<AdminStats | undefined> {
    try {
      const result = await dataApiRequest(
        `query {
        adminStats {
          subscriberCount merchantCount revenue
          lastCreatedAccounts ${lastCreatedAccountFragment}
          topReferrers ${topReferrersFragment}
        }
      }`
      );

      if (result && result.adminStats) {
        return result.adminStats as AdminStats;
      }
    } catch (error) {
      console.error("FAILED TO FETCH ADMIN STATS", error);
    }
  },
  async fetchMerchants(
    context: any
  ): Promise<LightMerchantDetail[] | undefined> {
    try {
      const result = await dataApiRequest(
        `query {
          allMerchants {
            id name firstName
            account { telephone creationDate referrer {id name firstName}}
          }
        }`
      );

      if (result && result.allMerchants) {
        return result.allMerchants as LightMerchantDetail[];
      }
    } catch (error) {
      console.error("FAILED TO FETCH MERCHANTS", error);
    }
  },
  async fetchUsers(context: any): Promise<LightUser[] | undefined> {
    try {
      const result = await dataApiRequest(
        `query {
          allUsers {
            id name firstName isAdmin
            account { telephone creationDate lastLogin  }
          }
        }`
      );

      if (result && result.allUsers) {
        return result.allUsers as LightUser[];
      }
    } catch (error) {
      console.error("FAILED TO FETCH MERCHANTS", error);
    }
  },
  async fetchSubscriptions(context: any): Promise<Subscription[] | undefined> {
    try {
      const result = await dataApiRequest(
        `query {
          allSubscriptions {
            id startDate endDate price payPhone isActive monthCount
            user { id name firstName }
            shop {
              id name 
              categoryId
            }
          }
        }`
      );

      if (result && result.allSubscriptions) {
        return result.allSubscriptions as Subscription[];
      }
    } catch (error) {
      console.error("FAILED TO FETCH ALL SUBSCRIPTIONS", error);
    }
  },

  async fetchTransactions(context: any): Promise<Transaction[] | undefined> {
    try {
      const result = await dataApiRequest(
        `query {
          allTransactions {
            id date amount description receiptUrl status type
            user { id name firstName }
          }
        }`
      );

      if (result && result.allTransactions) {
        return result.allTransactions as Transaction[];
      }
    } catch (error) {
      console.error("FAILED TO FETCH ALL SUBSCRIPTIONS", error);
    }
  },
  async fetchMerchantDetails(
    context: any,
    merchantId: string
  ): Promise<FullMerchantDetail | undefined> {
    const { state } = commonActionContext(context);
    try {
      const result = await dataApiRequest(
        `query($userId: String!) {
          userInfo(userId: $userId) {
        id name firstName
        stats { referralsCount availableFund }
        account { telephone creationDate }
        shops { id name contact1 contact2 categoryId insertionDate city {name department}}
        referrals {id name firstName account {telephone creationDate}}
        transactions {id transactionDate transactionAmount transactionDescription transactionReceiptUrl transactionType transactionStatus}
      }}`,
        {
          userId: merchantId,
        }
      );

      if (result && result.userInfo) {
        result.userInfo.shops.forEach((shop: any) => {
          shop.category = state.categories.find(
            (el) => el.id === shop.categoryId
          );
        });
        return result.userInfo as FullMerchantDetail;
      }
    } catch (error) {}
  },
  async fetchCategories(context: any): Promise<void> {
    const { state, commit } = commonActionContext(context);
    if (state.categories.length !== 0) return;
    const result = await dataApiRequest(
      `query {
        categories ${categoriesFragment}
      }`
    );

    if (result && result.categories) {
      commit.SET_CATEGORIES(result.categories);
    }
  },
  async fetchCities(context: any): Promise<void> {
    const { state, commit } = commonActionContext(context);
    if (state.cities.length !== 0) return;
    const result = await dataApiRequest(
      `query {
        cities ${citiesFragment}
      }`
    );

    if (result && result.cities) {
      commit.SET_CITIES(result.cities);
    }
  },
  async loadPlatformSetup(context: any): Promise<void> {
    const { state, commit } = commonActionContext(context);

    const result = await apiRequest("GET", "config");

    if (result?.data) {
      commit.SET_PLATFORM_SETUP(result.data);
    }
  },
  async fetchFeaturedShops(context: any): Promise<void> {
    // const userLocation = await getCurrentPosition();

    const { commit } = commonActionContext(context);

    const result = await dataApiRequest(
      `query {
        featuredShops ${displayableShopFragment}
      }`
    );

    if (result && result.featuredShops) {
      commit.SET_FEATURED_SHOPS(result.featuredShops);
    }
  },
  async fetchShopDetails(
    _: any,
    shopId: string
  ): Promise<FullShopInfo | undefined> {
    const result = await dataApiRequest(
      `query($shopId: ID!) {
        shopDetails(shopId: $shopId) ${fullShopInfoFragment}
      }`,
      {
        shopId,
      }
    );

    if (result && result.shopDetails) return result.shopDetails;

    return undefined;
  },
  updateAccessTokenAvailablity(context: any): void {
    const { commit } = commonActionContext(context);
    commit.SET_ACCESS_TOKEN_AVAILABILITY(getToken() !== undefined);
  },

  async fetchAllShops(context: any): Promise<void> {
    const { state, commit } = commonActionContext(context);
    const result = await dataApiRequest(
      `query {
        allShops ${displayableShopFragment}
      }`
    );

    console.log("result:", result);

    if (result && result.allShops) {
      commit.SET_ALL_SHOPS(result.allShops);
      // return result.allShops;
    }
  },
  async fetchAllCategories(context: any): Promise<void> {
    const { state, commit } = commonActionContext(context);
    if (state.categories.length) return;
    const result = await dataApiRequest(
      `query {
        categories ${categoriesFragment}
      }`
    );

    console.log("result:", result);

    if (result && result.categories) {
      commit.SET_CATEGORIES(result.categories);
      // return result.allShops;
    }
  },
};
