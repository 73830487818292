























































































































import { getCurrentPosition } from "@/helpers";
import { GqlGpsLocation } from "@/helpers/gql-schema";
import router from "@/router";
import store from "@/store";
import { CategoryState, DisplayableShop } from "@/store/common";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
} from "@vue/composition-api";
import eventBus from "@/event-bus";
import CategoryFilter from "@/components/global/CategoryFilter.vue";

export default defineComponent({
  name: "MapsWithShop",
  components: {
    CategoryFilter,
  },
  props: {
    categoryFilter: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    const state = reactive({
      centerLatLng: undefined as
        | undefined
        | Pick<GqlGpsLocation, "lng" | "lat">,
      shops: computed((): DisplayableShop[] => {
        let resultToDisplay = store.state.common.shops;
        if (state.categoryFilters?.length !== 0) {
          resultToDisplay = store.state.common.shops.filter((shop) =>
            state.categoryFilters?.includes(shop.category.id)
          );
        }

        if (state.nameFilter !== "") {
          resultToDisplay = store.state.common.shops.filter((shop) =>
            shop.name.toLowerCase().includes(state.nameFilter)
          );
        }

        return resultToDisplay;
      }),
      selectedShop: undefined as DisplayableShop | undefined,
      shopCategory: computed(
        (): CategoryState[] => store.state.common.categories
      ),
      categoryFilters: [] as string[],
      showUserPopup: false,
      nameFilter: "",
      showFilter: true,
    });

    onMounted(async () => {
      eventBus.$emit("loadedMapsPage");

      try {
        const userLocation = await getCurrentPosition();
        state.centerLatLng = userLocation;

        console.log("====userLocation:", userLocation);
      } catch (error) {
        console.log("============error:", error);
      }

      if (props.categoryFilter)
        state.categoryFilters.push(props.categoryFilter);

      store.dispatch.common.fetchCategories();
      store.dispatch.common.fetchAllShops();
      // await store.dispatch.common.fetchNearestShops();
    });

    onUnmounted(() => {
      eventBus.$emit("leavingMapsPage");
    });

    function isSelectedShop(shop: DisplayableShop) {
      return state.selectedShop?.id === shop.id;
    }

    function openInfoWindow(shop: DisplayableShop) {
      state.selectedShop = shop;
    }

    function closeInfoWindow() {
      state.selectedShop = undefined;
    }

    function goToShop() {
      router.push("/shop/details/" + state.selectedShop?.id);
    }

    function filterBy(categoryId: string) {
      if (state.categoryFilters?.includes(categoryId)) {
        const categoryIndex = state.categoryFilters.findIndex(
          (id) => id === categoryId
        );
        state.categoryFilters.splice(categoryIndex, 1);
      } else {
        state.categoryFilters?.push(categoryId);
      }
      context.emit("categoryFilter", categoryId);
    }

    function toggleFilterOverlay() {
      state.showFilter = !state.showFilter;
    }

    function getShopIconToDisplay(shop: DisplayableShop): string {
      return (
        (shop.hasActivePromotion
          ? shop.category.featuredIcon.url
          : shop.category.normalIcon.url) ?? "#"
      );
    }

    return {
      state,
      openInfoWindow,
      closeInfoWindow,
      isSelectedShop,
      goToShop,
      filterBy,
      toggleFilterOverlay,
      getShopIconToDisplay,
    };
  },
});
