

















































































import Paginator from "@/components/global/Paginator.vue";
import router from "@/router";
import store from "@/store";
import { Subscription, Transaction } from "@/store/common/actions";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import LoadingScreen from "@/components/LoadingScreen.vue";
import { transactionStatusToUserReadable } from "@/helpers";

export default defineComponent({
  name: "PromotionList",
  components: { Paginator, LoadingScreen },
  setup() {
    const transactions = ref<Transaction[]>([]);
    const isLoading = ref<boolean>(false);

    function viewElementDetail(elementId: string) {
      router.push("artisan-detail/" + elementId);
    }

    onMounted(async () => {
      isLoading.value = true;
      const result = await store.dispatch.common.fetchTransactions();
      isLoading.value = false;

      if (result) {
        transactions.value = result;
      }
    });

    return {
      transactions,
      viewElementDetail,
      isLoading,
      transactionStatusToUserReadable,
    };
  },
});
