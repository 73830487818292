import { apiRequest } from "@/helpers/api-call";
import { dataApiRequest } from "@/helpers/data-api-client";
import { ApiCallResult } from "@/helpers/types";
import { userActionContext } from ".";

export default {
  async login(
    context: any,
    payload: { telephone: string; password: string }
  ): Promise<ApiCallResult> {
    const { dispatch, commit, getters, state } = userActionContext(context);
    try {
      const result = await apiRequest(
        "POST",
        "auth/login/admin",
        payload,
        false
      );

      if (result && result.data) {
        if (result.data.success) {
          localStorage.setItem("accessToken", result.data.data.token);
          return {
            success: true,
          };
        }
      }

      return {
        success: false,
        message:
          "Impossible de vous connecter. Veuillez vérifier vos identifiants",
      };
    } catch (error) {
      return {
        success: false,
        message:
          "Une erreur est survenue lors de votre connexion, veuillez réessayer",
      };
    }
  },
  async getCurrentUserInfo(context: any): Promise<void> {
    const { commit, rootDispatch } = userActionContext(context);
    try {
      const result = await dataApiRequest(`query { me { name firstName }}`);

      if (result && result.me) {
        commit.SET_USER(result.me);
      }
    } catch (error) {
      // const typedError = error as ErrorFromBackend;
      rootDispatch.session.logout();
    }
  },
  logout(context: any) {
    const { rootCommit } = userActionContext(context);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userRole");
    rootCommit.common.SET_ACCESS_TOKEN_AVAILABILITY(false);
    return true;
  },
};
