<!-- Header Structure -->
<template>
  <div class="app-admin-sidebar">
    <div class="db-admin">
      <div class="dashboard-nav">
        <div class="dashboard-nav-inner">
          <ul data-submenu-title="Les Stats">
            <li class="active">
              <router-link to="/accueil"
                ><i class="fa fa-tachometer"></i> Accueil</router-link
              >
            </li>
            <li>
              <router-link to="/maps"
                ><i class="fa fa-map"></i> La carte</router-link
              >
            </li>
          </ul>
          <ul data-submenu-title="La Plateforme">
            <!-- <li>
              <router-link to="/messages"
                ><i class="fa fa-inbox"></i> Messages
                <span class="nav-tag messages">2</span></router-link
              >
            </li> -->
            <li>
              <router-link to="/artisans-categories"
                ><i class="fa fa-tags"></i> Les catégories</router-link
              >
            </li>
            <li>
              <router-link to="/artisans-liste"
                ><i class="fa fa-user-o"></i> Les artisans</router-link
              >
            </li>
            <li>
              <router-link to="/abonnements"
                ><i class="fa fa-calendar-plus-o"></i> Les
                abonnements</router-link
              >
            </li>
            <li>
              <router-link to="/transactions"
                ><i class="fa fa-dollar"></i> Les transactions</router-link
              >
            </li>
            <li>
              <router-link to="/promotions"
                ><i class="fa fa-calendar-o"></i> Les promotions</router-link
              >
            </li>
          </ul>
          <ul data-submenu-title="Compte">
            <li>
              <router-link to="/users"
                ><i class="fa fa-users"></i> Utilisateurs</router-link
              >
            </li>
            <li>
              <router-link to="/login"
                ><i class="fa fa-sign-out"></i> Déconnexion</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <a class="db-trigger" @click="toggleMenu()">
      <i class="fa fa-reorder"></i>
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    toggleMenu() {
      if ($("#wrapper").hasClass("sidebar-in")) {
        $("#wrapper").removeClass("sidebar-in");
      } else {
        $("#wrapper").addClass("sidebar-in");
      }
    },
  },
};
</script>
