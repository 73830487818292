




























































































































































import { defineComponent } from "@vue/composition-api";
import Avatar from "vue-avatar";

export default defineComponent({
  name: "Profile",
  components: {
    Avatar
  }
});
