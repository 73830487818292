



































































import router from "@/router";
import store from "@/store";
import { CategoryState } from "@/store/common";
import { LightMerchantDetail } from "@/store/common/actions";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import Vodal from "vodal";
import AddNewCategory from "@/components/forms/AddNewCategory.vue";

export default defineComponent({
  name: "CategoryList",
  components: { AddNewCategory },
  setup() {
    const state = reactive({
      showNewCategoryModal: false,
    });
    const categories = computed<CategoryState[]>(
      () => store.state.common.categories
    );
    const isLoading = ref(true);

    function viewElementDetail(elementId: string) {
      router.push("artisan-detail/" + elementId);
    }

    onMounted(async () => {
      isLoading.value = true;
      const result = await store.dispatch.common.fetchAllCategories();

      console.log("------------result:", result);
      isLoading.value = false;
    });

    return { state, categories, isLoading, viewElementDetail };
  },
});
