



































import { defineComponent, PropType } from "@vue/composition-api";
import { GqlUser } from "@/helpers/gql-schema";
import { TopReferrer } from "@/store/common/actions";

export default defineComponent({
  name: "BestReferrers",
  props: {
    referrers: {
      type: Array as PropType<TopReferrer[]>,
      required: true,
    },
  },
});
