import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import * as VueGoogleMaps from "vue2-google-maps";
import App from "./App.vue";
import Vodal from "vodal";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "maz-ui/lib/css/index.css";
import "maz-ui/lib/css/base.css";
import "maz-ui/lib/css/maz-input-tags.css";

// router
import router from "./router/index";

// include all css files
import "./lib/CustomCss";

import "vue-phone-number-input/dist/vue-phone-number-input.css";

import "vue2-animate/dist/vue2-animate.min.css";

import "vodal/common.css";
import "vodal/door.css";
import "sweetalert2/src/sweetalert2.scss";
import store from "./store";

(window as any).$ = require("jquery")(window as any).JQuery = require("jquery");

Vue.use(VueCompositionApi);

Vue.component(Vodal.name, Vodal);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBtdO5k6CRntAMJCF-H5uZjTCoSGX95cdk" // Add your here your google map api key
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  store: store.original,
  components: { App }
}).$mount("#app");
