



































































import Paginator from "@/components/global/Paginator.vue";
import router from "@/router";
import store from "@/store";
import { LightMerchantDetail } from "@/store/common/actions";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";

export default defineComponent({
  name: "ArtisansList",
  components: { Paginator },
  setup() {
    const merchants = ref<LightMerchantDetail[]>([]);
    const isLoading = ref(true);

    function viewElementDetail(elementId: string) {
      router.push("artisan-detail/" + elementId);
    }

    onMounted(async () => {
      isLoading.value = true;
      const result = await store.dispatch.common.fetchMerchants();
      isLoading.value = false;

      if (result) {
        merchants.value = result;
      }
    });

    return { merchants, isLoading, viewElementDetail };
  },
});
