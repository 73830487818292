

























































































import Paginator from "@/components/global/Paginator.vue";
import router from "@/router";
import store from "@/store";
import { LightMerchantDetail, LightUser } from "@/store/common/actions";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { formatDateForUser } from "@/helpers";

export default defineComponent({
  name: "UsersList",
  components: { Paginator },
  setup() {
    const users = ref<LightUser[]>([]);

    function viewElementDetail(elementId: string) {}

    onMounted(async () => {
      const result = await store.dispatch.common.fetchUsers();

      if (result) {
        users.value = result;
      }
    });

    return { users, viewElementDetail, formatDateForUser };
  },
});
