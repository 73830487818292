<template>
   <div class="dashboard-content">
   <div class="row">
      <div class="col-lg-12">
         <div id="add-listing">
            <!-- Section -->
            <div class="add-listing-section mb-4">
               <!-- Headline -->
               <div class="add-listing-headline">
                  <h3> Basic Informations</h3>
               </div>
               <!-- Title -->
               <div class="row with-forms">
                  <div class="col-md-12">
                     <div class="form-group">
                        <input type="text" placeholder="Business Name" class="form-control form-control-alternative">
                     </div>
                  </div>
               </div>
               <!-- Row -->
               <div class="row with-forms">
                  <!-- Status -->
                  <div class="col-md-6">
                     <select class="custom-select form-control-alternative mb-3" aria-placeholder="Category" >
                        <option label="blank">Select Category</option>
                        <option>Eat & Drink</option>
                        <option>Shops</option>
                        <option>Hotels</option>
                        <option>Restaurants</option>
                        <option>Fitness</option>
                        <option>Events</option>
                     </select>
                  </div>
                  <!-- Type -->
                  <div class="col-md-6">
                     <div class="form-group">
                        <input type="text" placeholder="Keywords" class="form-control form-control-alternative">
                     </div>
                  </div>
               </div>
               <!-- Row / End -->
            </div>
            <!-- Section / End -->
            <!-- Section -->
            <div class="add-listing-section mb-4">
               <!-- Headline -->
               <div class="add-listing-headline">
                  <h3> Location</h3>
               </div>
               <div class="submit-section">
                  <!-- Row -->
                  <div class="row with-forms">
                     <!-- City -->
                     <div class="col-md-6">
                        <select class="custom-select form-control-alternative mb-3" >
                           <option label="blank">Select City</option>
                           <option>New York</option>
                           <option>Los Angeles</option>
                           <option>Chicago</option>
                           <option>Houston</option>
                           <option>Phoenix</option>
                           <option>San Diego</option>
                           <option>Austin</option>
                        </select>
                     </div>
                     <!-- Address -->
                     <div class="col-md-6">
                        <div class="form-group">
                           <input type="text" placeholder="e.g. 964 School Street" class="form-control form-control-alternative">
                        </div>
                     </div>
                     <!-- City -->
                     <div class="col-md-6">
                        <div class="form-group">
                           <input type="text" placeholder="State" class="form-control form-control-alternative" >
                        </div>
                     </div>
                     <!-- Zip-Code -->
                     <div class="col-md-6">
                        <div class="form-group">
                           <input type="text" placeholder="Zip Code" class="form-control form-control-alternative" >
                        </div>
                     </div>
                  </div>
                  <!-- Row / End -->
               </div>
            </div>
            <!-- Section / End -->
            <!-- Section -->
            <div class="add-listing-section mb-4">
               <!-- Dropzone -->
               <dropzone id="myVueDropzone" class="mb-2" :options="dropzoneOptions">
                  <input type="hidden" name="token" value="xxx">
               </dropzone>
            </div>
            <!-- Section / End -->
            <!-- Section -->
            <div class="add-listing-section mb-4">
               <!-- Headline -->
               <div class="add-listing-headline">
                  <h3> Details</h3>
               </div>
               <div class="form">
                  <textarea class="form-control form-control-alternative" placeholder="Description" id="exampleFormControlTextarea1" rows="3"></textarea>
               </div>
               <!-- Row -->
               <div class="row with-forms">
                  <!-- Phone -->
                  <div class="col-md-4">
                     <input type="text" placeholder="Phone" class="form-control form-control-alternative" >
                  </div>
                  <!-- Website -->
                  <div class="col-md-4">
                     <input type="text" placeholder="Website" class="form-control form-control-alternative" >
                  </div>
                  <!-- Email Address -->
                  <div class="col-md-4">
                     <input type="text" placeholder="E-mail" class="form-control form-control-alternative" >
                  </div>
               </div>
               <!-- Row / End -->
               <!-- Row -->
               <div class="row with-forms">
                  <!-- Phone -->
                  <div class="col-md-4">
                     <h5 class="fb-input"><i class="fa fa-facebook-square"></i> Facebook <span>(optional)</span></h5>
                     <input type="text" class="form-control form-control-alternative" placeholder="https://www.facebook.com/">
                  </div>
                  <!-- Website -->
                  <div class="col-md-4">
                     <h5 class="twitter-input"><i class="fa fa-twitter"></i> Twitter <span>(optional)</span></h5>
                     <input type="text" class="form-control form-control-alternative" placeholder="https://www.twitter.com/">
                  </div>
                  <!-- Email Address -->
                  <div class="col-md-4">
                     <h5 class="gplus-input"><i class="fa fa-google-plus"></i> Google Plus <span>(optional)</span></h5>
                     <input type="text" class="form-control form-control-alternative" placeholder="https://plus.google.com">
                  </div>
               </div>
               <!-- Row / End -->
               <!-- Checkboxes -->
               <h5 class="margin-top-30 margin-bottom-10">Amenities <span>(optional)</span></h5>
               <div class="checkboxes in-row margin-bottom-20">
                  <input id="check-a" type="checkbox" name="check">
                  <label for="check-a">Elevator in building</label>
                  <input id="check-b" type="checkbox" name="check">
                  <label for="check-b">Friendly workspace</label>
                  <input id="check-c" type="checkbox" name="check">
                  <label for="check-c">Instant Book</label>
                  <input id="check-d" type="checkbox" name="check">
                  <label for="check-d">Wireless Internet</label>
                  <input id="check-e" type="checkbox" name="check" >
                  <label for="check-e">Free parking on premises</label>
                  <input id="check-f" type="checkbox" name="check" >
                  <label for="check-f">Free parking on street</label>
                  <input id="check-g" type="checkbox" name="check">
                  <label for="check-g">Smoking allowed</label> 
                  <input id="check-h" type="checkbox" name="check">
                  <label for="check-h">Events</label>
               </div>
               <!-- Checkboxes / End -->
            </div>
            <!-- Section / End -->
            <!-- Section -->
            <div class="add-listing-section mb-4">
               <!-- Headline -->
               <div class="add-listing-headline">
                  <h3> Opening Hours</h3>
                  <!-- Switcher -->
                  <label class="switch"><input type="checkbox" checked><span class="slider round"></span></label>
               </div>
               <!-- Switcher ON-OFF Content -->
               <div class="switcher-content">
                  <!-- Day -->
                  <div class="row opening-day">
                     <div class="col-md-2">
                        <h5>Monday</h5>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Opening Time">
                           <option label="Opening Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Closing Time">
                           <option label="Closing Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                  </div>
                  <!-- Day / End -->
                  <!-- Day -->
                  <div class="row opening-day js-demo-hours">
                     <div class="col-md-2">
                        <h5>Tuesday</h5>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Opening Time">
                           <option label="Opening Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Closing Time">
                           <option label="Closing Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                  </div>
                  <!-- Day / End -->
                  <!-- Day -->
                  <div class="row opening-day js-demo-hours">
                     <div class="col-md-2">
                        <h5>Wednesday</h5>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Opening Time">
                           <option label="Opening Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Closing Time">
                           <option label="Closing Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                  </div>
                  <!-- Day / End -->
                  <!-- Day -->
                  <div class="row opening-day js-demo-hours">
                     <div class="col-md-2">
                        <h5>Thursday</h5>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Opening Time">
                           <option label="Opening Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Closing Time">
                           <option label="Closing Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                  </div>
                  <!-- Day / End -->
                  <!-- Day -->
                  <div class="row opening-day js-demo-hours">
                     <div class="col-md-2">
                        <h5>Friday</h5>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Opening Time">
                           <option label="Opening Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Closing Time">
                           <option label="Closing Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                  </div>
                  <!-- Day / End -->
                  <!-- Day -->
                  <div class="row opening-day js-demo-hours">
                     <div class="col-md-2">
                        <h5>Saturday</h5>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Opening Time">
                           <option label="Opening Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Closing Time">
                           <option label="Closing Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                  </div>
                  <!-- Day / End -->
                  <!-- Day -->
                  <div class="row opening-day js-demo-hours">
                     <div class="col-md-2">
                        <h5>Sunday</h5>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Opening Time">
                           <option label="Opening Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                     <div class="col-md-5">
                        <select class="custom-select form-control-alternative mb-3" data-placeholder="Closing Time">
                           <option label="Closing Time"></option>
                           <option>Closed</option>
                           <option>1 AM</option>
                           <option>2 AM</option>
                           <option>3 AM</option>
                           <option>4 AM</option>
                           <option>5 AM</option>
                           <option>6 AM</option>
                           <option>7 AM</option>
                           <option>8 AM</option>
                           <option>9 AM</option>
                           <option>10 AM</option>
                           <option>11 AM</option>
                           <option>12 AM</option>
                           <option>1 PM</option>
                           <option>2 PM</option>
                           <option>3 PM</option>
                           <option>4 PM</option>
                           <option>5 PM</option>
                           <option>6 PM</option>
                           <option>7 PM</option>
                           <option>8 PM</option>
                           <option>9 PM</option>
                           <option>10 PM</option>
                           <option>11 PM</option>
                           <option>12 PM</option>
                        </select>
                     </div>
                  </div>
                  <!-- Day / End -->
               </div>
               <!-- Switcher ON-OFF Content / End -->
            </div>
            <!-- Section / End -->
            <!-- Section -->
            <div class="add-listing-section mb-4">
               <!-- Headline -->
               <div class="add-listing-headline mb-0">
                  <h3> Pricing</h3>
                  <!-- Switcher -->
                  <label class="switch"><input type="checkbox" checked><span class="slider round"></span></label>
               </div>
               <!-- Switcher ON-OFF Content -->
               <div class="switcher-content">
                  <div class="row">
                     <div class="col-md-12">
                        <table id="pricing-list-container">
                           <tr class="pricing-list-item pattern">
                              <td>
                                 <div class="fm-input pricing-name"><input type="text" placeholder="Title" class="form-control form-control-alternative" /></div>
                                 <div class="fm-input pricing-ingredients"><input type="text" placeholder="Description" class="form-control form-control-alternative" /></div>
                                 <div class="fm-input pricing-price"><input type="text" placeholder="Price" class="form-control form-control-alternative" /></div>
                              </td>
                           </tr>
                        </table>
                        <div class="mt-3">  <a href="#" class="btn btn-primary">Add Item</a>
                           <a href="#" class="btn btn-primary">Add Category</a>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- Switcher ON-OFF Content / End -->
            </div>
            <!-- Section / End -->
            <a href="#" class="btn btn-danger">Preview <i class="fa fa-arrow-circle-right"></i></a>
         </div>
      </div>
      <!-- Copyrights -->
      <div class="col-md-12">
         <div class="copyrights">© 2019 DirectView. All Rights Reserved.</div>
      </div>
   </div>
</div>
</template>

<script>
import Dropzone from "vue2-dropzone";
export default {
   components: {
      Dropzone
   },
   data() {
      return {
         dropzoneOptions: {
            url: "https://httpbin.org/post",
            thumbnailWidth: 150,
            maxFilesize: 0.5,
            headers: { "My-Awesome-Header": "header value" }
         }
      };
   }
};
</script>
