import { UserType } from "@/store/session";
import { TransactionStatus } from "shared-dependancies";
import { GqlGpsLocation } from "./gql-schema";

export const MAX_SHOP_GALLERY_FILES_COUNT = 4;

export interface EnvVariables {
  backendUrl: string;
}

export function isValidIdentityInfo(str: string) {
  return str.length >= 3;
}
export function isValidPassword(password: string) {
  return password.length >= 6;
}

export function getToken() {
  return localStorage.getItem("accessToken") ?? undefined;
}

export function getuserRole(): UserType | undefined {
  return (localStorage.getItem("userRole") as UserType) ?? undefined;
}

export function getEnvVariable() {
  return {
    backendUrl: process.env.VUE_APP_BACKEND_API_URL,
  };
}

export function getDropZoneOptions() {
  return {
    url: `${getEnvVariable().backendUrl}/gallery`,
    method: "POST",
    paramName: () => "photos",
    addRemoveLinks: true,
    thumbnailWidth: 150,
    maxFilesize: 2,
    maxFiles: MAX_SHOP_GALLERY_FILES_COUNT,
    acceptedFiles: "image/png,image/jpg,image/jpeg",
    autoProcessQueue: false,
    uploadMultiple: true,
    parallelUploads: 3,
    placeholder: "Déplacer les fichiers ici",
    dictDefaultMessage: "Déplacer les fichiers ici",
    dictFileTooBig:
      "Fichier trop gros ({{filesize}}MiB). Taille maxi autorisée: {{maxFilesize}}MiB.",
    dictCancelUpload: "Annuler l'envoi",
    dictRemoveFile: "Retirer ce fichier",
    dictMaxFilesExceeded: "Nombre maximum de fichier atteint",
    dictCancelUploadConfirmation: "Voulez-vous vraiment annuler l'envoi ?",
    dictInvalidFileType: "Le format de fichier n'est pas supporté",
  };
}

export async function getCurrentPosition(): Promise<GqlGpsLocation> {
  if (!navigator.geolocation) {
    throw new Error("Geolocation not supported");
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => reject(error)
    );
  });
}

export function haversineDistance(
  pos1: GqlGpsLocation,
  pos2: GqlGpsLocation
): number {
  const R = 6371.071; // Radius of the Earth in kilometers
  let rlat1 = pos1.lat * (Math.PI / 180); // Convert degrees to radians
  let rlat2 = pos2.lat * (Math.PI / 180); // Convert degrees to radians
  let difflat = rlat2 - rlat1; // Radian difference (latitudes)
  let difflon = (pos2.lng - pos1.lng) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );
  return d;
}

export const MOBILE_DEVICE_WIDTH = 576; // Arbritary set min width for mobile device to 576px (Bootstrap breakpoint)
export const TABLET_DEVICE_WIDTH = 990; // Arbritary set min width for mobile device to 576px (Bootstrap breakpoint)

export function getWindowWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

export function getDateFromFullTimeStamp(fullDate: string) {
  return fullDate.split("T")[0];
}

export function formatDateForUser(fullDate: string) {
  return fullDate.replace("T", " ");
}

export function transactionStatusToUserReadable(status: TransactionStatus) {
  const statusLabel = {
    Pending: "En cours",
    Failed: "Echec",
    Completed: "Achevée",
    Cancelled: "Annulé",
  };

  return statusLabel[status];
}
