<!-- App Main Structure -->
<template>
  <div id="wrapper">
    <!-- Header of Admin Panel -->
    <app-admin-header></app-admin-header>
    <div class="clearfix"></div>
    <div id="dashboard">
      <!-- Sidebar of Admin Panel -->
      <app-admin-sidebar></app-admin-sidebar>
      <!--Main Container -->
      <div id="main-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import AdminHeader from "Components/AdminHeader/AdminHeader.vue";
import AdminSidebar from "Components/AdminSidebar/AdminSidebar.vue";

export default {
  components: {
    appAdminHeader: AdminHeader,
    appAdminSidebar: AdminSidebar,
  },
};
</script>

<style lang="scss">
#main-container {
  // padding-left: 16.5rem;

  @media (max-width: 992px) {
    padding: 30px;
  }
}
</style>
