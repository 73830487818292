




import { defineComponent, onMounted } from "@vue/composition-api";
import store from "./store";
export default defineComponent({
  name: "App",
  setup() {
    onMounted(() => {
      store.dispatch.session.getCurrentUserInfo();
      store.dispatch.common.fetchCategories();
    });

    return {};
  },
});
