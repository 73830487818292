
























































































import { defineComponent, computed } from "@vue/composition-api";
import store from "@/store";

export default defineComponent({
  name: "AdminHeader",
  setup() {
    return {
      userIdentity: computed(() => store.getters.session.userIdentity),
    };
  },
});
