import Vue from "vue";
import Router from "vue-router";

import store from "@/store";

//routes
import adminendRoutes from "./admin";

// session components
const SignUp = () => import("../views/session/SignUp.vue");
const Login = () => import("../views/session/Login.vue");
const ForgotPassword = () => import("../views/session/ForgotPassword.vue");
const ComingSoon = () => import("../views/session/ComingSoon.vue");

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    adminendRoutes,
    {
      path: "/login",
      component: Login,
      meta: {
        title: "Login",
        // breadcrumb: 'Session / Login'
      },
    },
    {
      path: "/mot-de-passe-oublie",
      component: ForgotPassword,
      meta: {
        title: "Forgot Password",
        // breadcrumb: 'Session / Forgot Password'
      },
    },
  ],
});

const publicRoutes = ["/login", "/mot-de-passe-oublie"];

router.beforeEach(async (to, from, next) => {
  console.log("============router.beforeEach", to.path);

  await store.dispatch.common.updateAccessTokenAvailablity();

  if (
    !store.state.common.accessTokenAvailable &&
    !publicRoutes.includes(to.path)
  ) {
    router.push("/login");
    return;
  }
  next();
});

export default router;
