<template>
  <div class="container px-2">
    <h5>Ajouter une nouvelle catégorie</h5>
    <form id="contactForm">
      <div class="form-floating mb-3">
        <label for="nomDeLaCategorie">Nom de la catégorie</label>
        <input
          class="form-control"
          id="nomDeLaCategorie"
          type="text"
          placeholder=""
          data-sb-validations="required"
        />
        <div
          class="invalid-feedback"
          data-sb-feedback="nomDeLaCategorie:required"
        >
          Le nom de la catégorie est requis.
        </div>
        <div>
          <dropzone
            ref="vueDropzone"
            id="vueDropzone"
            class="mb-2"
            :options="dropZoneOptions"
          >
            <input type="hidden" name="token" value="xxx" />
          </dropzone>
        </div>
      </div>
      <div class="d-none" id="submitSuccessMessage">
        <div class="text-center mb-3">
          <div class="fw-bolder">Form submission successful!</div>
          <p>To activate this form, sign up at</p>
          <a href="https://startbootstrap.com/solution/contact-forms"
            >https://startbootstrap.com/solution/contact-forms</a
          >
        </div>
      </div>
      <div class="d-none" id="submitErrorMessage">
        <div class="text-center text-danger mb-3">Error sending message!</div>
      </div>
      <div class="d-grid">
        <button
          class="btn btn-primary btn-lg disabled"
          id="submitButton"
          type="submit"
        >
          Ajouter
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { reactive } from "vue";
import Dropzone from "vue2-dropzone";

export default defineComponent({
  name: "AddNewCategory",
  components: {
    Dropzone,
  },
  setup() {
    const state = reactive({
      count: 0,
    });

    const dropZoneOptions = reactive({
      url: `${process.env.VUE_APP_BACKEND_API_URL}/gallery`,
      method: "POST",
      paramName: () => "photos",
      addRemoveLinks: true,
      thumbnailWidth: 150,
      maxFilesize: 2,
      maxFiles: 1,
      acceptedFiles: "image/png,image/jpg,image/jpeg",
      autoProcessQueue: false,
      uploadMultiple: false,
      placeholder: "Choisir une image pour la categorie",
      dictDefaultMessage: "Choisir une image pour la categorie",
      dictFileTooBig:
        "Fichier trop gros ({{filesize}}MiB). Taille maxi autorisée: {{maxFilesize}}MiB.",
      dictCancelUpload: "Annuler l'envoi",
      dictRemoveFile: "Retirer ce fichier",
      dictMaxFilesExceeded: "Nombre maximum de fichier atteint",
      dictCancelUploadConfirmation: "Voulez-vous vraiment annuler l'envoi ?",
      dictInvalidFileType: "Le format de fichier n'est pas supporté",
    });

    return {
      state,
      dropZoneOptions,
    };
  },
});
</script>

<style lang="scss" scoped></style>
