import AdminendPanel from "../layouts/adminPanel/AdminPanel.vue";

// dashboard components
import Dashboard from "../views/adminPages/Dashboard.vue";
import Messages from "../views/adminPages/Messages.vue";
import Bookings from "../views/adminPages/Bookings.vue";
import Reviews from "../views/adminPages/Reviews.vue";
import Bookmarks from "../views/adminPages/Bookmarks.vue";
import ArtisansList from "../views/adminPages/ArtisansList.vue";
import SubscriptionList from "../views/adminPages/SubscriptionList.vue";
import PromotionList from "../views/adminPages/PromotionList.vue";
import TransactionList from "../views/adminPages/TransactionList.vue";
import AddList from "../views/adminPages/AddList.vue";
import Profile from "../views/adminPages/Profile.vue";
import MapsWithShop from "../views/listings/MapsWithShop.vue";
import ArtisanDetail from "../views/adminPages/ArtisanDetail.vue";
import UsersList from "../views/adminPages/UsersList.vue";
import CategoryList from "@/views/adminPages/CategoryList.vue";

const admin = {
  path: "/",
  component: AdminendPanel,
  redirect: "/accueil",
  children: [
    {
      path: "/accueil",
      component: Dashboard,
      meta: {
        title: "Dashboard",
      },
    },
    // {
    //   path: "/messages",
    //   component: Messages,
    //   meta: {
    //     title: "Messages",
    //   },
    // },
    {
      path: "/bookings",
      component: Bookings,
      meta: {
        title: "Bookings",
      },
    },
    {
      path: "/reviews",
      component: Reviews,
      meta: {
        title: "Reviews",
      },
    },
    {
      path: "/abonnements",
      component: SubscriptionList,
      meta: {
        title: "Abonnements",
      },
    },
    {
      path: "/transactions",
      component: TransactionList,
      meta: {
        title: "Transactions",
      },
    },
    {
      path: "/promotions",
      component: PromotionList,
      meta: {
        title: "Promotions",
      },
    },
    {
      path: "/bookmarks",
      component: Bookmarks,
      meta: {
        title: "Bookmarks",
      },
    },
    {
      path: "/artisans-categories",
      component: CategoryList,
      meta: {
        title: "Les categories",
      },
    },
    {
      path: "/artisans-liste",
      component: ArtisansList,
      meta: {
        title: "Liste des artisans",
      },
    },
    {
      path: "/artisan-detail/:artisanId",
      component: ArtisanDetail,
      meta: {
        title: "Détails d'un artisan",
      },
      props: true,
    },
    {
      path: "/maps",
      component: MapsWithShop,
      meta: {
        title: "Carte des artisans",
      },
    },
    {
      path: "/add-list",
      component: AddList,
      meta: {
        title: "Add List",
      },
    },
    {
      path: "/profile",
      component: Profile,
      meta: {
        title: "Profile",
      },
    },
    {
      path: "/users",
      component: UsersList,
      meta: {
        title: "Users",
      },
    },
  ],
};

export default admin;
