

















import { defineComponent, PropType } from "@vue/composition-api";

export type Stat = {
  value: string;
  label: string;
};

export default defineComponent({
  name: "StatCard",
  props: {
    stat: {
      type: Object as PropType<Stat>,
      required: true,
    },
  },
});
