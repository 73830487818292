

































import { defineComponent, PropType } from "@vue/composition-api";
import { LightUser } from "@/store/common/actions";

export default defineComponent({
  name: "LastSubscription",
  props: {
    lastCreatedAccounts: {
      type: Array as PropType<
        (LightUser & { account: { creationDate: string } })[]
      >,
      required: true,
    },
  },
});
