































































































































































type TabName = "referrals" | "transactions";

import {
  computed,
  defineComponent,
  reactive,
  onMounted,
} from "@vue/composition-api";
import Avatar from "vue-avatar";
import StatCard, { Stat } from "@/components/global/StatCard.vue";
import store from "@/store";
import router from "@/router";
import LoadingScreen from "@/components/LoadingScreen.vue";
import { FullMerchantDetail } from "@/store/common/actions";
import {
  getDateFromFullTimeStamp,
  transactionStatusToUserReadable,
} from "@/helpers";

type State = {
  currentTab: TabName;
  loading: boolean;
  detail?: FullMerchantDetail;
};

export default defineComponent({
  name: "ArtisanDetail",
  props: {
    artisanId: String,
  },
  components: { Avatar, StatCard, LoadingScreen },
  setup(props) {
    const state = reactive<State>({
      currentTab: "referrals",
      loading: false,
    });

    const statsForDisplay = computed((): Stat[] => [
      {
        value: (state.detail?.stats.referralsCount || 0).toString(),
        label: "Filleuls",
      },
      {
        value: (state.detail?.shops.length || 0).toString(),
        label: "Ateliers",
      },
      {
        value: (state.detail?.stats.availableFund ?? 0) + " FCFA",
        label: "Solde disponible",
      },
    ]);

    function setCurrentTab(tab: TabName) {
      state.currentTab = tab;
    }

    onMounted(async () => {
      if (!props.artisanId) return router.push("/");
      state.loading = true;

      state.detail = await store.dispatch.common.fetchMerchantDetails(
        props.artisanId
      );

      state.loading = false;
    });

    return {
      state,
      setCurrentTab,
      statsForDisplay,
      getDateFromFullTimeStamp,
      transactionStatusToUserReadable,
    };
  },
});
