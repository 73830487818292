import {
  GqlCategory,
  GqlCity,
  GqlGallery,
  GqlGpsLocation,
  GqlPicture,
  GqlPromotion,
  GqlShop,
  GqlTeamMember,
  GqlUser,
} from "@/helpers/gql-schema";
import { VuexPick } from "@/helpers/vuex-type-helper";
import { defineModule } from "direct-vuex";
import { PlatformSetup } from "shared-dependancies";
import { moduleActionContext } from "..";
import actions from "./actions";

export type CategoryState = VuexPick<
  GqlCategory,
  "id" | "label" | "picture" | "featuredIcon" | "normalIcon" | "shopCount"
>;
export type CityState = VuexPick<GqlCity, "id" | "name" | "department">;
export type PictureState = VuexPick<GqlPicture, "url">;
export type LocationState = VuexPick<GqlGpsLocation, "lng" | "lat">;
export type GalleryState = VuexPick<GqlGallery, "id"> & {
  pictures: PictureState[];
};
export type ManagerState = VuexPick<GqlUser, "firstName" | "name">;
export type PromotionState = VuexPick<
  GqlPromotion,
  | "id"
  | "isActive"
  | "startDate"
  | "endDate"
  | "price"
  | "payPhone"
  | "promotionText"
> & {
  gallery: GalleryState;
};

export type DisplayableShop = VuexPick<
  GqlShop,
  "id" | "name" | "hasActivePromotion" | "description" | "contact1" | "contact2"
> & {
  city: CityState;
  category: CategoryState;
  firstPicture: PictureState;
  location: LocationState;
  distanceToUser: number;
};

export type FullShopInfo = DisplayableShop & {
  gallery: GalleryState;
  manager: ManagerState;
  currentlyActivePromotion: PromotionState;
};

export interface CommonModuleState {
  categories: CategoryState[];
  cities: CityState[];
  platformSetup: PlatformSetup;
  routeWaitingForLoggedUser: string | null;
  shops: DisplayableShop[];
  featuredShops: DisplayableShop[];
  accessTokenAvailable: boolean;
}

function initialState(): CommonModuleState {
  return {
    categories: [],
    cities: [],
    platformSetup: {
      setupId: null,
      monthlyFee: null,
      referralPercent: null,
      minimumAmountToWithdral: null,
      frontendUrl: null,
      promotionDailyPrice: null,
    },
    routeWaitingForLoggedUser: null,
    shops: [],
    featuredShops: [],
    accessTokenAvailable: false,
  };
}

export const commonModule = defineModule({
  namespaced: true,
  state: (): CommonModuleState => {
    return initialState();
  },
  getters: {},
  actions,
  mutations: {
    SET_CATEGORIES(state, categories: CategoryState[]) {
      state.categories = categories;
    },
    SET_CITIES(state, cities: CityState[]) {
      state.cities = cities;
    },
    SET_PLATFORM_SETUP(state, setup: PlatformSetup) {
      Object.assign(state.platformSetup, setup);
      state.platformSetup = setup;
    },
    SET_WAITING_FOR_LOGIN_ROUTE(state, route: string) {
      state.routeWaitingForLoggedUser = route;
    },
    SET_NEAREST_SHOPS(state, shops: DisplayableShop[]) {
      state.shops = shops;
    },
    SET_FEATURED_SHOPS(state, shops: DisplayableShop[]) {
      state.featuredShops = shops;
    },
    RESET_WAITING_FOR_LOGIN_ROUTE(state) {
      state.routeWaitingForLoggedUser = null;
    },
    SET_ACCESS_TOKEN_AVAILABILITY(state, status: boolean) {
      state.accessTokenAvailable = status;
    },
    SET_ALL_SHOPS(state, shops: DisplayableShop[]) {
      console.log("shops:", shops);
      state.shops = shops;
    },
  },
});

export const commonActionContext = (context: any) =>
  moduleActionContext(context, commonModule);
