


























































































































import Paginator from "@/components/global/Paginator.vue";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  components: { Paginator },
});
