
















































import {
  defineComponent,
  reactive,
  onMounted,
  computed,
} from "@vue/composition-api";
import store from "@/store";
import LoadingScreen from "@/components/LoadingScreen.vue";
import BestReferrers from "@/components/Dashboard/BestReferrers.vue";
import LastSubscription from "@/components/Dashboard/LastSubscription.vue";
import { AdminStats } from "@/store/common/actions";
import StatCard, { Stat } from "@/components/global/StatCard.vue";

type State = {
  loading: boolean;
  stats?: AdminStats;
};

export default defineComponent({
  components: { LoadingScreen, BestReferrers, LastSubscription, StatCard },
  name: "Accueil",
  setup() {
    const state = reactive<State>({
      loading: false,
    });

    const statsForDisplay = computed((): Stat[] => [
      {
        value: (state.stats?.subscriberCount ?? 0).toString(),
        label: "Abonnés",
      },
      {
        value: (state.stats?.merchantCount ?? 0).toString(),
        label: "Marchands",
      },
      {
        value: (state.stats?.revenue ?? 0).toString(),
        label: "Chiffres d'affaires",
      },
    ]);

    onMounted(async () => {
      state.loading = true;
      const stats = await store.dispatch.common.fetchAdminStats();
      state.loading = false;

      console.log("stats:", stats);

      if (stats) {
        state.stats = stats;
      }
    });

    return {
      state,
      statsForDisplay,
    };
  },
});
