import { GqlAccount, GqlUser } from "@/helpers/gql-schema";
import { VuexPick } from "@/helpers/vuex-type-helper";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "..";
import actions from "./actions";

export type UserType = "admin" | "user";

export type UserState = VuexPick<GqlUser, "name" | "firstName">;

export interface UserModuleState {
  currentUser: UserState | null;
}

function initialState(): UserModuleState {
  return {
    currentUser: null,
  };
}

export const sessionModule = defineModule({
  namespaced: true,
  state: () => {
    return initialState();
  },
  getters: {
    userIdentity: (state) => {
      if (!state.currentUser) return "";
      return state.currentUser?.name + " " + state.currentUser?.firstName;
    },
  },
  mutations: {
    SET_USER(state, user: UserState) {
      state.currentUser = user;
    },
  },
  actions,
});

export const userActionContext = (context: any) =>
  moduleActionContext(context, sessionModule);
